<template>
  <div>
    <div class="listing-back-btn">
      <router-link to="/budget">
        <unicon name="arrow-left"></unicon>वार्षिक विकास कार्यक्रम बजेटमा
        फर्कनुहोस्
      </router-link>
    </div>
    <div class="program-hero" v-if="programs.length">
      <div @click="leftRotateArr" class="hero-icon hero-icon--up">
        <unicon name="angle-up" />
      </div>
      <div @click="rightRotateArr" class="hero-icon hero-icon--down">
        <unicon name="angle-down" />
      </div>
      <div class="program-hero__background">
        <transition name="hero-img-fade" mode="out-in">
          <img
            :src="programs[programs.length - 1]['imageid3after']"
            :key="programs[programs.length - 1]['imageid3after']"
            alt=""
            v-if="programs.length"
          />
        </transition>
      </div>
      <div class="program-hero-container">
        <transition name="hero-card-fade" mode="out-in">
          <ProgramCard
            :program="programs[programs.length - 1]"
            :showThumb="true"
            v-on:moreDetailClickHandler="programCardClickHandler"
            v-on:pauseInterval="pauseInterval"
            v-on:resumeInterval="resumeInterval"
          />
        </transition>
      </div>
    </div>
    <div class="program-list" v-if="programs.length">
      <transition-group
        :name="cardsSlideUp ? 'flip-list-up' : 'flip-list-down'"
      >
        <ProgramCard
          v-for="program in programs"
          :key="program['s.no.']"
          :program="program"
          :showThumb="true"
          v-on:programCardClickHandler="programCardClickHandler"
          v-on:pauseInterval="pauseInterval"
          v-on:resumeInterval="resumeInterval"
        />
      </transition-group>
    </div>
    <div class="program-list__no-data" v-else>
      <NoDataFound />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.autoRotateArr();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  data: function () {
    return {
      programs: [],
      interval: "",
      isIntervalPaused: false,
      cardsSlideUp: true
    };
  },
  computed: {
    fiscalYear() {
      return this.$store.getters.fiscal_year;
    },
    selectedYearlyPrograms() {
      return this.$store.getters.selectedYearlyPrograms;
    }
  },
  components: {
    ProgramCard: () => import("./programCard.vue")
  },
  watch: {
    selectedYearlyPrograms: {
      handler: "filteredSelectedYearlyPrograms",
      immediate: true
    },
    fiscalYear: {
      handler: "filteredSelectedYearlyPrograms"
    }
  },
  methods: {
    filteredSelectedYearlyPrograms() {
      switch (this.$route.params.programName) {
        case "social":
          this.programs = this.selectedYearlyPrograms.filter((program) => {
            return (
              program.fiscalyear === this.fiscalYear &&
              program.category === "सामाजिक विकास"
            );
          });
          break;
        case "financial":
          this.programs = this.selectedYearlyPrograms.filter((program) => {
            return (
              program.fiscalyear === this.fiscalYear &&
              program.category === "आर्थिक विकास"
            );
          });
          break;
        case "institutional":
          this.programs = this.selectedYearlyPrograms.filter((program) => {
            return (
              program.fiscalyear === this.fiscalYear &&
              program.category === "संस्थागत विकास, सेवा प्रवाह र सुशासन"
            );
          });
          break;
        case "infrastructure":
          this.programs = this.selectedYearlyPrograms.filter((program) => {
            return (
              program.fiscalyear === this.fiscalYear &&
              program.category === "पूर्वाधार विकास"
            );
          });
          break;
        case "environment":
          this.programs = this.selectedYearlyPrograms.filter((program) => {
            return (
              program.fiscalyear === this.fiscalYear &&
              program.category === "वातावरण तथा विपद व्यवस्थापन"
            );
          });
          break;
        default:
          this.programs = [];
      }
    },
    rightRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      // carousel slide up
      this.cardsSlideUp = false;
      // right rotate array
      let el = this.programs.pop();
      setTimeout(() => this.programs.unshift(el), 300);
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    leftRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      this.cardsSlideUp = true;
      if (this.programs.length !== 0) {
        let el = this.programs.shift();
        setTimeout(() => this.programs.push(el), 300);
      }
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    autoRotateArr() {
      this.setIntervalForLeftRotation();
    },
    programCardClickHandler(id) {
      this.$router.push({
        name: "Program-detail",
        params: { programId: id }
      });
    },
    setIntervalForLeftRotation() {
      this.interval = setInterval(() => {
        if (!this.isIntervalPaused) {
          this.leftRotateArr();
        }
      }, 10000);
    },
    pauseInterval() {
      this.isIntervalPaused = true;
    },
    resumeInterval() {
      this.isIntervalPaused = false;
    }
  }
};
</script>

<style>
</style>
